.card__default-business {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 250px;
    height: 90px;
    align-items: center;
    padding: 10px;
    border: 1px solid green;
    border-radius: 8px;
    cursor: pointer;
}

.card__default-business i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: rgb(25, 135, 84, .3);
}

.card__default-business:hover i {
    background-color: var(--green);
    color: #fff;
}