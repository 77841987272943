/* User section */
.add-new__chat {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    background: var(--green);
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 1px 4px 5px #dbeae3;
    transition: all ease-in-out 300ms;
}

.add-new__chat:hover {
    box-shadow: none;
}

.message .avatar,
.user .avatar {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    position: relative;
    background-color: var(--green);
    border-radius: 50%;
    color: #fff;
    overflow: hidden;
}

.avatar img {
    width: 100%;
}

.message .avatar {
    width: 35px;
    height: 35px;
    margin-right: 1rem;
}

.message.left .avatar {
    margin-right: 0;
    margin-left: 1rem;
}

.user .body {
    flex-grow: 1;
    flex-basis: 0;
}

.user .body span {
    display: block;
}

.user .body span:nth-child(2) {
    font-size: 0.8em;
    color: #929292;
}

.user .user-status {
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    background-color: #ADADAD;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    border: 2px solid #fff;
}

.user .user-status.online {
    background-color: #49DD45;
}

.user .user-status.busy {
    background-color: #5160F0;
}

.user .user-status.disconnected {
    background-color: #ADADAD;
}

.notification {
    display: block;
    width: auto;
    float: right;
    background-color: #4981D5;
    color: #fff;
    padding: 0 8px;
    border-radius: 8px;
}

.active__chat {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background-color: rgb(219, 234, 227, .2);
}

/* Chat section */
.chat-section {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 70vh;
}

.chat-section.business__chat {
    height: 84vh;
}

.messages {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
}

.messages .load-more__chat {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 5rem;
    width: 10em;
    cursor: pointer;
    background-color: var(--green);
    color: #fff;
    text-align: center;
    border-radius: 20px;
    font-size: .9rem;
    box-shadow: 0px 1px 4px 4px #dbeae3;
}

.messages .load-more__chat:hover {
    box-shadow: none;
}

/* Message */
.message {
    font-size: 1.1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-family: var(--lato);
}

.message.left {
    justify-content: flex-end;
}

.message img {
    margin-bottom: 0.5rem;
}

.message .body {
    font-size: 0.85em;
    padding: 10px 10px;
    background-color: #dbeae3;
    position: relative;
    min-width: 2rem;
    max-width: 40%;
    border-radius: 0px 15px 15px 15px;
}

@media screen and (max-width: 768px) {
    .message .body {
        max-width: 60%;
    }
}

.message.large .body {
    max-width: calc(100% - 35px - 1rem - 5rem) !important;
    width: calc(100% - 35px - 1rem - 5rem) !important;
}

.message.left .body {
    background-color: var(--green);
    color: #fff;
    border-radius: 15px 0px 15px 15px;
}

.message .text {
    display: block;
    position: relative;
}

.option__editor {
    display: block;
    border-radius: 50rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 180px;
    float: right;
}

.option__editor:hover {
    opacity: .9;
}

.sidebar__chats {
    background-color: rgb(219, 234, 227, .2);
}

.sidebar-recent__chats {
    display: block;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

.recent__chat {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #cfded7;
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
}

.recent__chat .information__chat {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
}

.recent__chat .information__chat .name-time__chat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recent__chat.active {
    background-color: #dbeae3;
}

.recent__chat .avatar,
.active__chat .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50rem;
    object-fit: cover;
    background-color: #ccc;
    color: #000;
    margin-right: 5px;
}

.last-message__chat {
    display: inline-block;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.badge__messages {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--green);
    color: #fff;
    font-size: .7rem
}

.context-menu__chat {
    position: absolute;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 1px 1px 5px #ccc;
    font-family: "Lato";
    z-index: 1000;
}

.context-menu__chat .list-users__chat {
    max-height: 25rem;
    overflow-x: auto;
}

.input-container {
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
}

.input-container .send {
    box-shadow: 0px 1px 4px 5px #dbeae3;
    transition: all ease-in-out 300ms;
}

.input-container .send:hover {
    box-shadow: none;
}

.text-area__chat {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 20px;
    width: 100%;
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px 10px;
}

.text-area__chat textarea {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    resize: none;
    height: 25px;
    max-height: 150px;
    overflow-y: auto;
    margin-left: 10px;
}

.text-area__chat .emoji-picker__chat {
    position: relative;
    font-size: 1.2rem;
    color: var(--green);
}

/* Not selected Chat */

.empty__chat {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-family: var(--lato);
    font-weight: 400;
}

.empty__chat img {
    height: 250px;
    margin-bottom: 20px;
}