.custom-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 30px;
    height: 36px;
}

.custom-search input[type="text"] {
    background-color: transparent;
    margin-left: 5px;
}