.menu-bar {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
}

.menu-bar .item-menu {
    display: block;
    cursor: pointer;
    border-radius: 0px 20px 20px 0px;
    padding: 3px 10px;
    margin-bottom: 2px;
}

.menu-bar .item-menu:hover,
.menu-bar .item-menu.active {
    color: #fff;
    background-color: #198754;
}

.list-emails {
    display: block;
    border-radius: 1rem;
    background-color: #D3E8DF;
    padding: .8rem;
    width: 100%;
    height: calc(100vh - 8rem) !important;
    max-height: calc(100vh - 8rem) !important;
    overflow-y: auto;
}

.create-mail {
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: flex;
    width: 26rem;
    height: 27rem;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    flex-direction: column;
}

.create-mail .params {
    color: #8b8f8d;
}

.create-mail .input-subject {
    display: block;
    width: 100%;
    border: none;
    outline: none;
}

.create-mail .border-bottom {
    border-bottom: 1px solid #ccc;
}

.create-mail .body {
    overflow-y: scroll;
    height: 22rem;
}

.create-mail .content {
    width: 100%;
    height: 18rem;
    max-height: 18rem;
    outline: none;
    border: none;
    resize: none;
    padding: 5px;
}

.email-message {
    padding: 5px 10px;
    border-bottom: 1px solid #81dfb3;
    font-family: "Lato";
    cursor: pointer;
    align-items: center;
    height: 2.7rem;
}

.email-message .recipients,
.email-message .subject {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.email-message:hover {
    box-shadow: 1px 1px 3px #4d9775;
}

.email-message .icon-button {
    display: none;
}

.email-message:hover .icon-button {
    display: inline-block;
}

.search-select {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
}

.search-select input {
    width: 100%;
}

.search-select .filtered-users {
    display: block;
    position: absolute;
    bottom: -10rem;
    left: 0;
    height: 10rem;
    max-height: 10rem;
    background-color: var(--light);
    width: 130%;
    box-shadow: 1px 1px 3px #4d9775;
    border-radius: 10px;
    overflow-y: auto;
}

.filtered-users .card-select-user .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-select-user .avatar .circle {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filtered-users .card-select-user {
    padding: 3px 2px;
    border-bottom: 1px solid #ccc;
}

.filtered-users .card-select-user .identifier {
    display: flex;
    flex-direction: column;
}

.card-selected-recipient {
    border-radius: 20px;
    border: 1px solid #ccc;
    align-items: center;
    margin-bottom: 3px;
}

.select-background {
    display: inline-block;
    border: 2px solid #198754;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.select-background .item {
    display: inline-block;
    padding: 2px 10px;
    cursor: pointer;
}