/* .div-nav-prinicipal */
.principal-div-search-content {
    background-color: white;
    height: 45px !important;
    border-radius: 7px;
    box-shadow: #ccc 2px 2px 2px;
    border: dashed #158B3D 1px;
}

/* .buscador */
.second-div-search-content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background-color: #fff !important;
}

/* .buscador ul */
.second-div-search-content ul {
    list-style: none;
    padding: 3px;
    margin-bottom: 0 !important;
}

/* .buscador ul li*/
.second-div-search-content ul li {
    display: inline-block;
}

/* .lupa */
.primary-item-search-lupa {
    font-size: 18px;
    border: none;
    background: transparent !important;
    opacity: .3;
}

/* .buscador ul li select */
.second-div-search-content ul li select {
    border: none;
}

/* buscador ul li input */
.second-div-search-content ul li input {
    border: none !important;
    width: 100%;
}

/* buscador ul li input:focus */
.second-div-search-content ul li input:focus {
    border: none !important;
    outline: none !important;
}

/* inputText */
.second-item-search-input-text {
    margin-left: 7px;
    padding-left: 15px;
    width: 55%;
}

/* selecciona */
.third-item-search-select {
    float: right;
}

/* selecciona select */
.third-item-search-select select {
    background-color: rgb(250, 250, 250);
    border: medium none;
    height: 30px;
    width: 250px;
}

.move-parameterization {
    display: inline-block;
    min-width: 40px;
    margin-left: 5px;
}

.move-parameterization i {
    display: none;
}

tr:hover .move-parameterization i, .parent-actions:hover .move-parameterization i,
.tree_label:hover .move-parameterization i
{
    display: inline-block;
}

.move-parameterization__select {
    display: inline-block;
    min-width: 40px;
    margin-left: 5px;
    opacity: .7;
}

.move-parameterization__select i {
    display: inline-block;
}