/* ESTILOS BAJO ESTUDIO - ELIMINAR LOS NO USADOS */

/* Contenedor principal de todo el formulario  ---------------------------*/
.principal-container {
    padding: 20px 10px;
}

/* Buscador del tercero -------------------------------------------------- */

.principal-content-search-tercero {
    background-color: #fafafa !important;
    height: 45px !important;
    border-radius: 7px;
}

.tercero-buscador {
    width: 100% !important;
    height: 100% !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background-color: #fafafa !important;
    opacity: .5;
}

.tercero-buscador ul {
    list-style: none;
    padding: 3px;
    margin-bottom: 0 !important;
    height: 100% !important;
}

.tercero-buscador ul li {
    display: inline-block;
}

.search-tercero-input {
    width: 84%;
    height: 100%;
    background-color: #fafafa !important;
    padding-left: 10px;
}

.search-tercero-input input {
    height: 100%;
    background-color: #fafafa !important;
    border: none !important;
    width: 100%;
}

.search-tercero-input input:focus {
    border: none !important;
    outline: none !important;
}

.input-generate-tercero {
    border: 0;
    background-color: #fafafa;
}

/* No sirve */
.input-generate-tercero:focus {
    border: none !important;
    outline: none !important;
}

.buton-modal-search-tercero {
    border: none;
    background: transparent !important;
    opacity: .9;
    margin-left: 4px;
}

.img-button-tercero {
    float: initial !important;
    width: 25px !important;
    height: 25px !important;
}



/* FORM CHECK (WhatsApp y Correo)----------------------------------- */

.btn-switch-input-whatsapp {
    display: none;
}

/* Label */
.lbl-switch {
    display: inline-block;
    width: 130px;
    max-height: 30px;
    background-color: #e9e9e9 !important;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
}

.btn-switch-input-whatsapp:checked~.lbl-switch {
    border: 1px solid green;
}


/* BOTON VISUALIZAR DOCUMENTO */
.buton-visualizarDocumento {
    margin-left: 8.3rem !important;

}

/* Card Favorite */
.card-favorite {
    border-radius: 10px;
    padding-left: 20px;
    border: solid #ccc 1px;
    cursor: pointer;
    min-width: 12rem;
}

.card-favorite-selected {
    border: solid var(--green) 1px !important;
    background-color: rgba(21, 139, 61, .1);
}

.abs-center {
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
}

.tiny {
    font-size: 10px;
    text-overflow: ellipsis;
}

/* Busqueda de terceros */
.search-bar {
    background-color: #fff;
}

.input-borderless {
    outline: none;
    border: none;
    width: 100%;
}

/* ESTILOS SÍ USADOS */

.card__section-favorite-codes {
    border: dashed var(--green) 1px !important;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
}

/* estilos de email */
.divemail {
    display: flex;
    flex-direction: column;
}

.divemail-title {
    display: flex;
    flex-direction: row;
}

.divemail-titlelabel {
    font-size: 20px;
    height: auto;

}

.divemail-body {
    display: flex;
    flex-direction: column;
    padding: 35px;
}

.divemail-bodyform {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    padding-top: 20px;
}

.divemail-footcontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.divemail-button1 {
    height: 36px;
    background-color: #198754;
    color: #fff;
    border: 0.5px solid #e0e0e0;
    border-radius: 6px 0 0 6px;
    width: 50%;
}

.divemail-button2 {
    height: 36px;
    background-color: #ffffff;
    border: 0.5px solid #e0e0e0;
    border-radius: 0 6px 6px 0;
    width: 50%;
}

.nav-configuration {
    flex-direction: column;
    min-width: 14rem;
}

.nav-configuration .nav-item {
    color: #198754;
    background-image: none;
}

.nav-configuration .nav-item .nav-link i {
    color: #198754;
    font-size: 1.3rem;
}

.nav-configuration .nav-item .nav-link {
    display: flex;
    align-items: center;
    color: #000;
    background-image: none;
    padding: 15px 12px;
}

.nav-configuration .nav-item .nav-link.active {
    background-color: var(--green-opaque);
}

.accordion .accordion-item .accordion-header .accordion-button i {
    color: #198754 !important;
}

.accordion .accordion-item .accordion-header .accordion-button.collapsed {
    color: #000;
}

.accordion .accordion-item .accordion-header .accordion-button {
    color: #198754;
}