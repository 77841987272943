.stepper {
    height: 100%;
    width: 100%;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0px 20px 0px 20px;
}

.buttons-stepper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.stepWrapper {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5px;
    overflow-y: hidden;
    overflow-x: auto;
}

.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: start;
}

.circle {
    width: 35px;
    height: 35px;
    line-height: 35px;
    background-color: #dddddd;
    /* Color verde de la letra */
    color: rgb(255, 255, 255);
    border-radius: 17%;
    /*Borde verde*/
    border: 1px solid;
    border-color: #E6E6E6;
    cursor: pointer;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-size: 18px;
}

.stepBlock .circleWrapper {
    padding: 0px 3px;
    position: relative;
}

.selected .circle {
    color: rgb(255, 255, 255);
    background-color: #198754;
    border: 1px solid;
    border-color: rgba(19, 140, 64, 255);
}

/* Eliminar la línea que conecta los elementos */
.stepBlock:not(:last-child) > .circleWrapper::after {
    content: none; /* Esto evitará que se muestre la línea */
}


@media (max-width: 576px) {
    .circle {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
    }
}

/* ------------------------------------------------------------------------------------------------------- */
/* Deprecated */
.show-form {
    display: block;
}

/* Deprecated */
.hide-form {
    display: none;
}

/* Deprecated */
.stepform-contenedor-campos {
    min-height: 300px;
}

.steper-input-label {
    text-align: left !important;
}

.parametrizacion__form-input {
    background-color: rgba(231, 243, 236, 255) !important;
    outline: none;
    border: none;
}

.parametrizacion__form-textarea {
    height: 35px;
    max-height: 10rem;
}

.parametrizacion__form-input:focus {
    outline: none !important;
    border: none !important;
    background-color: rgba(231, 243, 236, 255);
}

/* Componente accordeon */
.accordion-stepper-form {
    margin: 10px 0px 0px 0px !important;
}

.accordion-item-stepper-form {
    --bs-accordion-btn-icon-width: 1.25rem !important;
}

.accordion-header-stepper-form button {
    padding: 0px !important;
    margin: 0px !important;
    background-color: transparent;
    color: black;
    cursor: pointer;
    padding: 10px 7px !important;
    height: 55px !important;
}