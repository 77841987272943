.context-menu__expedient {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 5px #ccc;
    font-family: "Lato";
    z-index: 1000;
    width: 250px;
    max-height: 300px;
    overflow-y: auto;
}

.context-menu__expedient .item-color {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 15px;
}

.context-menu__expedient .item-color:hover {
    background-color: #efefef;
}

.context-menu__expedient .item-color .circle-color {
    display: inline-flex;
    width: 30px;
    height: 30px;
    border-radius: 30px;    
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.context-menu__expedient .item-color .text-status {
    display: inline-block;
    font-size: .8rem;
    max-width: 120px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}