/* ————————————————————–
    Tree core styles
  */
.tree {
    margin: 1em;
    min-height: 10rem;
    max-height: 25rem;
    overflow-y: auto;
}

.tree input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.tree input~ul {
    display: none;
}

.tree input:checked~ul {
    display: block;
}

/* ————————————————————–
    Tree rows
  */
.tree li {
    line-height: 1.2;
    position: relative;
    padding: 0 0 1em 1em;
}

.tree ul li {
    padding: 1em 0 0 1em;
}

.tree>li:last-child {
    padding-bottom: 0;
}

/* ————————————————————–
    Tree labels
  */
.tree_label {
    position: relative;
    /* display: inline-block; */
    background: #fff;
    width: 100%;
}

div.tree_label {
    cursor: pointer;
}

div.tree_label:hover {
    color: #666;
}

/* ————————————————————–
    Tree expanded icon
  */
div.tree_label:before {
    background: #138c40;
    color: #fff;
    position: relative;
    z-index: 1;
    float: left;
    margin: 0 1em 0 -2em;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    content: '+';
    text-align: center;
    line-height: .9em;
    font-weight: bold;
}

:checked~div.tree_label:before {
    background: #403294;
    content: '–';
}

/* ————————————————————–
    Tree branches
  */
.tree li:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -.5em;
    display: block;
    width: 0;
    border-left: 1px solid #777;
    content: "";
}

.tree_label:after {
    position: absolute;
    top: 0;
    left: -1.5em;
    display: block;
    height: 0.5em;
    width: 1.05em;
    border-bottom: 1px solid #777;
    border-left: 1px solid #777;
    border-radius: 0 0 0 .3em;
    content: '';
}

div.tree_label:after {
    border-bottom: 0;
}

:checked~div.tree_label:after {
    border-radius: 0 .3em 0 0;
    border-top: 1px solid #777;
    border-right: 1px solid #777;
    border-bottom: 0;
    border-left: 0;
    bottom: 0;
    top: 0.5em;
    height: auto;
}

.tree li:last-child:before {
    height: 1em;
    bottom: auto;
}

.tree>li:last-child:before {
    display: none;
}

.tree_custom {
    display: block;
    background: #eee;
    padding: 1em;
    border-radius: 0.3em;
}

ul {
    padding-left: 0px !important;
}