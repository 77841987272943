.welcome-block {
    display: block;
    background: linear-gradient(to right, #198754, #1aa775);
    border-radius: 20px;
    box-shadow: 3px 3px 8px rgba(25, 135, 84, .5);
}

.usage__percentage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30;
}

.usage__percentage-card {
    display: block;
    width: 15rem;
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 20px;
    color: white;
    padding: 15px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    background: linear-gradient(to top, #0f9355, #4abc87);
}

.usage__percentage-counter {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    border-radius: 0px 20px 0px 20px;
    color: purple;
    font-weight: 600;
    background-color: #fff;
    padding: 5px 2px;
    text-align: center;
    font-size: 13px;
}

.block__custom-doughnut {
    display: block;
    padding: 1em;
    border-radius: 20px;
    background-color: rgb(25, 135, 84, .1);
    max-width: 14rem;
    margin-left: auto;
    margin-right: auto;
}

.card-views {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 110px;
    align-items: center;
    padding: 10px;
    border: 1px solid green;
    border-radius: 8px;
}

.card-views i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: rgb(25, 135, 84, .3);
}

@media (max-width: 770px) {
    .usage__percentage {
        justify-content: center;
    }
}

@media (min-width: 1700px) {
    .usage__percentage-card {
        width: 20rem;
    }
}