
/*  --- COMPONENTE MODAL-COPY-GROUP -----------------------  */
/* Modal  */
.modal-body-copy-group {
    background-color: #FAFAFA !important;
}

/* Accordion */

.accordion-copy-group {
    margin: 15px 0px 0px 0px !important;
}

.accordion-item-copy-group {
    --bs-accordion-btn-icon-width: 1.25rem !important;
    border-radius: 10px !important;
}

.accordion-header-copy-group button {
    padding: 0px !important;
    margin: 0px !important;
    background-color: transparent;
    color: black;
    cursor: pointer;
    padding: 10px 7px !important;
    height: 55px !important;
}

.accordion-body-copy-group {
    cursor: auto !important;
}

/* --- BOTONES PARTA PEGAR  ----------------------------------------------------------------- */

.button-paste-persona {
    display: block;
    margin: auto;
    outline: none;
    border: none;
    margin-top: 7px;
}

.button-paste-category{
    display: block;
    margin: auto;
    outline: none;
    border: none;
}