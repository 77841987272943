.dashboard__layout {
    display: grid;
    position: relative !important;
    height: 100vh;
    width: 100vw;
    grid-template-rows: 60px 1fr;
    grid-template-columns: 256px 1fr;    
    animation: ease-in-out 600ms;
}

.dashboard__layout.short {
    grid-template-columns: 50px 1fr;
}

.header__layout {
    height: 56px;
    grid-column: 2 / 3;
    color: white;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    background: var(--light);
    display: flex;
    grid-gap: 24px;
    font-family: var(--lato);
}

.sidebar-menu__layout {
    grid-row: 1 / 3;
    background-color: var(--green);
    color: white;
}

.content__layout {
    display: flex;
    width: 100%;
    overflow-x: auto;
    max-width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;
    background-color: #fff;
}

.header__layout .profile img {
    width: 42px;
    height: 42px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0px;
}

@media(max-width: 992px) {
    .sidebar-menu__layout {
        display: none;
    }
    
    .dashboard__layout {
        grid-template-columns: 1fr;
    }

    .content__layout {
        grid-column: 1/3;
    }
}