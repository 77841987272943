/* ----- TODOS LOS ESTILOS DEL STORAGE USERS ---- */
/* --------------------------------------------------- */
.tittle-upload-files,
.tittle-loaded-files {
    font-family: var(--lato);
}

.drop-files {
    position: relative;
    width: 380px;
    height: 200px;
    border: 1px dashed var(--green);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--input-bg);
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
}

.drop-files input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-files i {
    color: #198754;
    opacity: .64;
    font-size: 60px;
    transition: all ease-in-out 300ms;
}

.drop-files .description {
    color: #6A6A6A;
}

.drop-files button {
    background-color: none;
    border-radius: 8px;
    border: 2px solid #198754;
    color: #198754;
    font-weight: bold;
    padding: 5px 10px;
}

.drop-files:hover i {
    opacity: 9;
}

.uploaded-files {
    background-color: rgb(82, 161, 234, .07);
    width: 100%;
    border-radius: 20px;
    min-height: 250px;
    padding: 20px;
}

/* --------------------------------------------------------------- */

.drop-file-preview__item {
    display: flex;
    padding: 10px 10px;
    direction: row;
    justify-content: space-between;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 8px;
    border-radius: 10px;
    height: 50px;
    cursor: pointer;
}

.drop-file-preview__item.pending-upload {
    background-color: rgb(82, 161, 234, .07);
    align-items: center;
    max-width: 380px;
}

.drop-file-preview__item.icon-name {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}

.drop-file-preview__item .title {
    display: inline-flex;
    flex-direction: row;
}

.drop-file-preview__item .body {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.body .main-title {
    max-width: 280px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
}

.removeMemoryItemsFileButton {
    background-color: transparent;
    border: medium none;
    display: block;
    float: right;
    margin-top: 14px;
    opacity: 0.6;
}

.removeMemoryItemsFileButton:hover {
    opacity: 1;
}


/* ------------------------------------------------------------------ */



.buttonSendFiles {
    display: block;
    margin-left: auto;
    margin-right: 0px !important;
    margin-top: 15px !important;
}

.actionButtonFileBD {
    background-color: transparent;
    border: medium none;
    display: block;
    margin-top: 14px;
    opacity: 0.6;
    float: initial;
}

.actionButtonFileBD:hover {
    opacity: 1;
}

.actionButtonFileBD img {
    height: 30px;
    width: 30px;
}