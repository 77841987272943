/* BUSCADOR */
.overflow-scroll {
    overflow: scroll;
}

.offcanvas {
    width: 500px !important;
}

.sidebar-search-principal-content {
    padding-top: 10px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fafafa;
    border-radius: 20px;
}

/* --------------------------------------------------------------------------------------- */

.sidebar-search-second-content {
    background-color: #e9e9e9;
    height: 55px !important;
    border-radius: 7px;
}

.sidebar-search-buscador {
    width: 100% !important;
    height: 100% !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background-color: #e9e9e9 !important;
    opacity: .5;
}

.sidebar-search-buscador ul {
    list-style: none;
    padding: 3px;
    margin-bottom: 0 !important;
    height: 100% !important;
}

.sidebar-search-buscador ul li {
    display: inline-block;
}


.sidebar-search-inputText {
    /* margin-left: 7px;
    padding-left: 15px; */
    width: 90%;
    height: 100%;
    background-color: #e9e9e9 !important;

}

.sidebar-search-inputText input {
    outline: none;
    height: 100%;
    background-color: #e9e9e9 !important;
    border: none !important;
    width: 100%;
}

.sidebar-search-inputText input:focus {
    border: none !important;
    outline: none !important;
}

.sidebar-search-lupa {
    font-size: 22px;
    border: none;
    background: transparent !important;
    opacity: .9;
}


/* ---------------------------------------------------------------------------------------
ACCORDION*/
.accordion-item {
    border: none;
    outline: none !important;
}

.accordion-item:hover {
    outline: none !important;
    background-color: #e9e9e9 !important;
    cursor: pointer;
}

/* Este es el mismo 'accordion-header' */
.accordion-item h2 {
    /* outline: none !important;
    border: none !important; */
    padding: 0px !important;
    margin: 0px !important;
    background-color: transparent !important;
    color: black !important;
    cursor: pointer !important;
}

.accordion-header button {

    /* outline: none !important;
    border: none; */
    padding: 0px !important;
    margin: 0px !important;
    background-color: transparent;
    color: black;
    cursor: pointer;
    padding: 10px 7px !important;
}

.accordion-children-container {
    margin-left: 30px !important;
}

.smallLetter {
    float: left;
    font-size: 0.75em;
    color: #6c757d
}

.spanLetter {
    float: left;
    font-size: 18px;
    color: black;
    line-height: 16px;
}

.personImage {
    float: initial !important;
    width: 28px !important;
    height: 28px !important;
    margin-top: 5px !important;
    margin-left: 3px;
}

.content-accordion-body {
    width: 100%;
    padding-left: 6.2rem;
    padding-top: 9px;
    padding-bottom: 3px;
}

.content-accordion-body:hover {
    background-color: #e9e9e9 !important;
}

.content-accordion-body p {
    line-height: 15px;
    margin: 0px;
    font-size: 17px;
    color: black;

}

.content-accordion-body small {
    line-height: 10px;
    font-size: 0.85em;
    color: #6c757d;
}