.custom-tabs .nav-item .nav-link.active {
    background-color: #dbeae3;
    background-image:none;
}

.custom-tabs .nav-item .nav-link:hover {
    background-color: #dbeae3;
    background-image:none;
}

.custom-tabs .nav-item .nav-link {
    background-image: linear-gradient(to right, #f4f4f4, #ebebeb);
}