.pagination .page-item .page-link:focus,
.pagination .page-item .page-link:hover {
    color: #1e7e34;
    background-color: #e2e6ea;
    border-color: #ddd;
}

.pagination .page-item .page-link {
    color: var(--green)
}

.pagination .page-item.active .page-link {
    color: #fff;
    background-color: var(--green);
    border-color: #28a745;
}

.select__pagination {
    width: 100px !important;
    margin-left: auto;
}