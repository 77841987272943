.folder-container {
    display: inline-flex;
    align-items: center;
}

.folder-icon {
    position: relative;
    display: inline-block;
    z-index: 10;
    margin-top: 4px;
}

.folder-icon .back {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 60%;
    height: 100%;
    z-index: -1;
    border-radius: 3px;
    filter: brightness(.6);
}

.folder-icon .front {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 80%;
    z-index: -1;
    border-radius: 4px;
}