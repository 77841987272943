.button-save-document {
    margin-right: 0px !important;
}

.unordered__list-style {
    margin-left: 20px;
}

.unordered__list-style li {
    list-style: disc;
    margin-bottom: 10px;
}

.card-actor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
}

.card-actor .description {
    width: 60%;
}

.card-actor .label {
    display: flex;
    align-items: center;
    width: 40%;
    color: var(--green);
    font-weight: bold;
}

.card-actor:hover {
    background-color: var(--green-opaque);
}

.card-actor.active {
    background-color: var(--green-opaque);
}