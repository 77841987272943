button.rowcont {
    padding: 0 400px;
}

.label-question {
    height: 50px;
    color: #198754
}

.border {
    border: 5px solid #D9D9D9;
    padding: 20px;
}

.borderAns {
    padding: 0px 0px;
    border: 1px solid #D9D9D9;
}

.titleDiag {
    color: #198754;
}

.titleQues {
    color: #198754;
}

.square {
    background-color: #198754;
    color: #F2F2F2;
    border-radius: 10px;
}

.colQuest {
    padding: 0px;
    margin-top: 40px;
}

.divQuest {
    padding: 0px;
    margin-top: 40px;
}

.colButtons {
    padding: 0px 20px;
    margin: 30px 0px;
}

.buttonDoc {
    border-radius: 17%;
}

/*Estilos moviles*/

@media (max-width: 576px) {
    .rowcont {
        padding: 0px 0px;
        transform: scale(0.8) translateX(-15px);
    }
}

@media (max-width: 768px) {
    .rowcont {
        padding: 0px 0px;
        padding-left: 0%;
        transform: scale(0.9) translateX(-15px);

    }

    .colDoc {
        padding: 0px;

    }

    .border {
        padding: 0px;
    }

    .process-tabDoc {
        padding: 0px;
    }

    .docEdit {
        padding: 0px;
    }

    .pText {
        font-size: 12px;
    }

    .titleDiag {
        font-size: 12px;
    }

    .colQuest {
        font-size: 15px;
        transform: scale(0.8) translateX(-20px);
    }
}

@media screen and (max-width: 1598px) {
    .rowcont {
        padding: 0px 0px;
    }

    .colDoc {
        padding: 0px;
    }

    .border {
        padding: 0px;
    }

    .process-tabDoc {
        padding: 0px;
    }

    .docEdit {
        padding: 0px;
    }

}

@media screen and (max-width: 1328px) {
    .rowcont {
        padding: 0px 0px;
    }

    .colDoc {
        padding: 0px;
    }

    .border {
        padding: 0px;
    }

    .process-tabDoc {
        padding: 0px;
    }

    .docEdit {
        padding: 0px;
    }

}
