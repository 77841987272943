.card-custom__config {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	padding-left: 10px;
}

.containercard {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap; 
}

.inercard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Estilos móviles */
@media (max-width: 768px) {
	.card-custom__config {
		padding-left: 0px;
		width: 40px;
	}

	.containercard {
		font-size: 13px;
		align-items: center; 
		transform: scale(0.8) translateX(15px);
		flex-wrap: nowrap; 
		overflow-x: auto; 
	}

	.inercard {
		margin-top: 10px;
		align-items: center;
	}
}
