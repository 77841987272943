.textcolor {
  color: #969696;
  border-radius: 5px;
}

.div-categorias {
  display: flex;
  height: 60px;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #b5b4b4;
}

.container-parent {
  max-width: 823px;
  min-width: 400px;
  container-type: inline-size;
  container-name: cat2-container;
}

.container-parent2 {
  width: 650px;
  height: 90px;
  container-type: inline-size;
  container-name: cat3-container;
  overflow-x: auto;
}

/* Estilos para .divcat2 */
.divcat2 {
  display: flex;
  flex-wrap: nowrap;
  /* Evita que los elementos se envuelvan */
  gap: 16px;
  height: 46px;
  overflow-x: auto;
  /* Permite scroll horizontal */
}


/* Container query */
@container cat2-container (min-inline-size: 823px) {
  .divcat2 {
    flex-wrap: wrap;
    /* Permite que los elementos se envuelvan */
    height: auto;
    /* Altura automática */
    max-height: 44px;
    /* Altura máxima cuando está en modo columna */
    overflow-y: auto;
    /* Permite scroll vertical */
    overflow-x: hidden;
    /* Oculta el scroll horizontal */

  }
}

@container cat2-container (min-inline-size: 523px) {
  .divcat2 {
    flex-wrap: wrap;
    /* Permite que los elementos se envuelvan */
    height: auto;
    /* Altura automática */
    max-height: 44px;
    /* Altura máxima cuando está en modo columna */
    overflow-y: auto;
    /* Permite scroll vertical */
    overflow-x: hidden;
    /* Oculta el scroll horizontal */

  }
}

@container cat2-container (min-inline-size: 323px) {
  .divcat2 {
    flex-wrap: wrap;
    /* Permite que los elementos se envuelvan */
    height: auto;
    /* Altura automática */
    max-height: 44px;
    /* Altura máxima cuando está en modo columna */
    overflow-y: auto;
    /* Permite scroll vertical */
    overflow-x: hidden;
    /* Oculta el scroll horizontal */

  }
}

@container cat2-container (min-inline-size: 223px) {
  .divcat2 {
    flex-wrap: wrap;
    /* Permite que los elementos se envuelvan */
    height: auto;
    /* Altura automática */
    max-height: 44px;
    /* Altura máxima cuando está en modo columna */
    overflow-y: auto;
    /* Permite scroll vertical */
    overflow-x: hidden;
    /* Oculta el scroll horizontal */

  }

}

@container cat2-container (min-inline-size: 150px) {
  .divcat2 {
    flex-wrap: wrap;
    /* Permite que los elementos se envuelvan */
    height: auto;
    /* Altura automática */
    max-height: 44px;
    /* Altura máxima cuando está en modo columna */
    overflow-y: auto;
    /* Permite scroll vertical */
    overflow-x: hidden;
    /* Oculta el scroll horizontal */

  }
}

@container cat3-container (min-inline-size: 823px) {
  .divcat2 {
    flex-wrap: wrap;
    /* Permite que los elementos se envuelvan */
    height: auto;
    /* Altura automática */
    max-height: 60px;
    /* Altura máxima cuando está en modo columna */
    overflow-y: auto;
    /* Permite scroll vertical */
    overflow-x: hidden;
    /* Oculta el scroll horizontal */
  }
}

.icon {
  color: #198754;
  width: 22px;
  height: 25px;
}

.icon2 {
  padding-top: 6px;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  color: #198754;
  transition: 0.3s ease all;

  &:hover {
    color: #29ac6f;
  }
}

.a {
  padding-top: 3px;
  margin-right: 6px;
  color: #969696;
}

.label__calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: 4px;
  padding: 2px 3px 2px 10px;
  flex-shrink: 0;
}

.calendar {
  max-width: 1100px;
  margin: 40px auto;
}

.sidebar-selected-events {
  display: grid;
  grid-template-rows: 50px 1fr;
  background-color: white;
  border-radius: 4px;
  width: 100%;
  height: 78vh;
}

.list-events {
  overflow-y: auto;
}

.fc-today-button,
.fc-next-button,
.fc-prev-button {
  background-color: #198754 !important;
  border-color: #198754;
}

.fc-col-header-cell-cushion {
  color: rgba(150, 150, 150, 1) !important;
  text-transform: uppercase;
}

.fc-daygrid-day-number {
  color: rgba(150, 150, 150, 1);
  user-select: none;
  cursor: pointer;
}

.item__label-calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 36px;
  border-radius: 20px;
  color: #fff;
  padding: 2px 12px;
  cursor: pointer;
  font-family: var(--lato);
  font-weight: 500;
}

.dropdown-locale {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  gap: 5px;
  padding: 10px;
}

.divcalendar-2-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(25, 135, 84, 0.5);
  color: #198754;
  font-size: 18px;
  padding: 10px;

}

.ContenedorBtn {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.btnmodal {
  display: block;
  padding: 10px 30px;
  border-radius: 100px;
  color: #fff;
  border: none;
  background: #392287;
  cursor: pointer;
  font-family: "poppins", sans-serif;
  font-weight: 500;
  transition: 0.3s ease all;

  &:hover {
    background: #51408d;
  }
}

.color-pick {
  margin: 0px 0px;
}

.divinput {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.divbotones {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 20px;
}

.colorGridStyle {
  display: grid;
  grid-template-columns: repeat(12, 0.5fr);
  gap: 2px;
  min-height: 80px;
}

@media (max-width: 720px) {
  .title-style {
    font-size: 14px;
  }

  .colorGridStyle {
    width: 350px;
  }
}

@media (max-width: 630px) {
  .colorGridStyle {
    grid-template-columns: repeat(6, 0.5fr);
    width: 300px;
  }
}

@media (max-width: 580px) {
  .colorGridStyle {
    width: 200px;
  }
}

.color__picker-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.card-event {
  display: flex;
  height: 100px;
  border-radius: 8px;
  border-left: 1px solid #838383;
  border-top: 1px solid #838383;
  border-right: 1px solid #838383;
  margin: 4px;
  padding: 5px 10px;
}

.card-event .time {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
}

.card-event .time .notification-event {
  display: block;
  font-size: .8rem;
  background-color: var(--green);
  text-align: center;
  border-radius: 4px;
  color: #fff;
  width: 80px;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
}

.card-event .main {
  display: grid;
  grid-template-rows: 50px 46px;
  width: 70%;
  height: 96px;
  font-size: .9rem;
}

.card-event .main .title {
  text-overflow: ellipsis;
}

.card-event .main .actions {
  display: flex;
  justify-content: end;
  opacity: 0;
}

.card-event:hover .main .actions {
  opacity: 1;
}

.tooltiptext {
  visibility: hidden;
  height: 211.2px;
  width: 250px;
  background-color: rgb(255, 255, 255);
  color: #000000;
  text-align: center;
  border-radius: 8px;
  padding: 5px 0;
  position: relative;
  z-index: 1;
  top: -118%;
  right: 73%;
  box-shadow: 0px 1px 3px 0px;
  padding-top: 0px;
}

.divcalendar-2-bodyinterno {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

/* Estilo para el día actual */
.fc-day-today.custom-day-cell .fc-daygrid-day-top {
  display: flex;
}

.fc-day-today {
  background-color: transparent !important;
}

.fc-day-today.custom-day-cell .fc-daygrid-day-number {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(25, 135, 84, 1);
  border-radius: 50%;
  color: white;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
  padding: 5px;
}

.fc-daygrid-day-events {
  cursor: pointer;
}

.headertooltip {
  border-radius: 8px 8px 0px 0px;
  color: #ffffff !important;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bodytooltip {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  height: 110px;
  gap: 10px;
  padding: 10px;
  align-items: stretch;
  z-index: 1000;
}

.divtooltip {
  display: flex;
  justify-content: flex-start;
}

.botoncontainertool {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  gap: 4px;
}

.divselecthour {
  width: 187px;
  height: 36px;
  border-radius: 4px;
}

.labelsmodal2 {
  display: flex;
  flex-direction: row;
  width: 546px;
  height: 36px;
  gap: 15px;
}

.containermodal2 {
  display: flex;
  flex-direction: row;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #fff;
  background-color: rgba(25, 135, 84, 1);
  border-color: var(--bs-btn-active-border-color);
}

.btn-secondary {
  color: #fff;
  background-color: rgba(25, 135, 84, 1);
  --bs-btn-hover-bg: rgba(25, 135, 84, 1);
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  background-color: rgba(25, 135, 84, 1);
}

.input-n2 {
  margin-top: 2px;
  color: rgb(0, 0, 0);
  margin-bottom: 8px;

  ::placeholder {
    color: rgb(189, 189, 189);
    font-weight: bold;
  }

  background-color: #ffffff;
  border: none;
  border-radius: 2px;
  height: 38px;
  width: 176px;
  border: 1px solid rgba(25, 135, 84, 1);
  border-radius: 5px;
  font-size: 15px;

  .time-picker .selected,
  .time-picker .active {
    background-color: rgba(25, 135, 84, 1);
  }
}

.input-n3 {
  margin-top: 2px;
  color: rgb(0, 0, 0);
  margin-bottom: 8px;

  ::placeholder {
    color: rgb(189, 189, 189);
    font-weight: bold;
  }

  background-color: #ffffff;
  border: none;
  border-radius: 2px;
  height: 38px;
  width: 100px;
  border: 1px solid rgba(25, 135, 84, 1);
  border-radius: 5px;
  font-size: 15px;

  .time-picker .selected,
  .time-picker .active {
    background-color: rgba(25, 135, 84, 1);
  }
}

.bodyinterno-datetime {
  padding-left: 19px;
}

@media screen and (max-width: 1152px) {
  .container-parent {
    width: 600px;
  }
}



@media screen and (max-width: 1016px) {
  .container-parent {
    width: 500px;
  }

  .tooltiptext {
    top: 50%;
    right: -30%;

  }

  .sidebar-selected-events {
    margin-top: 0px;
    width: 100%;


  }

}

@media screen and (max-width: 822px) {
  .container-parent {
    width: 400px;
  }
}

@media screen and (max-width: 718px) {
  .container-parent {
    width: 300px;
  }

  .div-categorias {
    justify-content: center;

  }
}

@media screen and (max-width: 631px) {
  .container-parent2 {
    width: 200px;
  }

}

@media screen and (max-width: 618px) {
  .div-categorias {
    justify-content: center;
    height: 100px;

  }

  .fc .fc-daygrid-day-frame {
    height: 75px;
  }

  .tooltiptext {
    right: -10%;
    height: 189px;
    font-size: 12px;
    width: 220px;

  }

  .fc-col-header-cell-cushion {
    font-size: 12px;
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 20px;
  }

  .fc .fc-daygrid-day-frame {
    height: 65px;
  }

  .divbotones {
    padding: 0px;
    margin: 35px;
  }
}

@media screen and (max-width: 430px) {
  .labelsmodal2 {
    width: 323px;
  }
}

@media screen and (max-width: 420px) {
  .container-parent {
    width: 200px;
  }
}


@media screen and (max-width: 370px) {
  .labelsmodal2 {
    flex-direction: column;
    height: 85px;
    width: 150px;
  }

  .tooltiptext {
    right: -9%;
    height: 149px;
    font-size: 12px;
    width: 171px;

  }

  .fc-col-header-cell-cushion {
    font-size: 9px;
  }

  .fc .fc-daygrid-day-frame {
    height: 45px;
  }

  .fc-daygrid-day-number {
    font-size: 10px;
  }

  .fc-h-event .fc-event-title {
    font-size: 10px;
  }

  .fc-direction-ltr .fc-daygrid-event .fc-event-time {
    font-size: 10px;
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 14px;
  }

  .fc .fc-toolbar-title {
    font-size: 19px;
  }

  .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
    height: 30px;
    width: 30px;
    padding: 0px;
  }

  .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
    height: 30px;
    width: 30px;
    padding: 0px;
  }

  .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    height: 34px;
    width: 40px;
    padding: 0px;
    margin: 0px;
  }
}