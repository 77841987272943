.container-login {
  position: absolute;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

.sign-in-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 2rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  z-index: 2;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #dbeae3;
  margin: 10px 0;
  height: 55px;
  border-radius: 50px;
  display: grid;
  grid-template-columns: 15% 85%;
  position: relative;
  overflow: hidden;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: var(--green);
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: transparent;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.btn-login:hover {
  background-color: #4d84e2;
}

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container-login:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-color: var(--green);
  transition: 500ms ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.8s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 6;
}

.panel img {
  height: 400px;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.btn-login.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

@media (max-width: 870px) {
  .container-login {
    min-height: 800px;
    height: 100vh;
  }

  .signin-signup {
    width: 100%;
    top: 75%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container-login.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: col;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .panel img {
    height: 300px;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 480px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .btn-login.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container-login:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container-login.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container-login.sign-up-mode .left-panel .image,
  .container-login.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container-login.sign-up-mode .right-panel .image,
  .container-login.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container-login.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }

  .panel {
    flex-direction: column;
    padding: 2rem 1rem 1rem 1rem;
  }

  .panel img {
    height: 220px;
    border-bottom: 1px solid var(--green);
  }

  .container-login {
    padding: 1.5rem;
  }

  .container-login:before {
    bottom: 72%;
    left: 50%;
  }

  .container-login.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

.cookiesContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 30px 30px 70px;
}

button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}

button.accept {
  background-color: #233dc7;
  border: none;
  border-radius: 5px;
  width: 200px;
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(35, 61, 199, 1);
}

.pass {
  margin: -5px 0 20px 5px;
  color: #a6a6a6;
  cursor: pointer;
}

.pass:hover {
  color: #233dc7;
  text-decoration: underline;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.txt_field {
  position: relative;
  border-bottom: 2px solid #adadad;
  margin: 30px 0;
  top: -30px;
}

.txt_field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: .5s;
}

.txt_field span::before {
  content: '';
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #233dc7;
  transition: .5s;
}

.txt_field input:focus~label,
.txt_field input:valid~label {
  top: -5px;
  color: #233dc7;
}

.txt_field input:focus~span::before,
.txt_field input:valid~span::before {
  width: 100%;
}

.stack {
  position: relative;
  width: 100%;
  height: 100vh;
}

.brackground-reset {
  background-color: #f3f1fc;
  overflow: hidden;
}

.medium-bar {
  background-color: var(--green);
  width: 100%;
  height: 50vh;
}

.floating-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

.form-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding: 30px;
  max-width: 30rem;
  min-height: 40rem;
  background-color: #fff;
  z-index: 100;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(75, 75, 75, .3);
}

.important-message {
  font-weight: 600;
}