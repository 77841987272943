.card-model__form {
    display: block;
    float: left;
    margin: 10px;
    border-radius: 20px;
    color: #1C3244;
    padding: 15px 10px;
    border: 1px dashed #403294;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    box-shadow: 4px 4px 10px rgba(64, 50, 148, .4),
        -6px -6px 6px #fff;
    background-color: #fff;
    overflow: hidden;
}

.description-card__form {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 56px;
}

.description-card__form p {
    text-wrap: wrap;
}

.card-model__form:hover {
    box-shadow: none;
}

.card-model__form-company {
    background-color: #fcdb98;
}

.text-ellipsis {
    text-overflow: ellipsis;
}

@media (max-width: 576px) {
    .card-model__form {
        height: 290px;
    }

    .card-model__form img {
        width: 40%;
    }

    .description-card__form {
        max-height: 20px;
    }
}

@media (min-width: 577px) and (max-width: 992px) {
    .card-model__form {
        height: 290px;
    }

    .card-model__form img {
        width: 25%;
    }
}

@media (min-width: 993px) {
    .card-model__form {
        height: 140px;
    }

    .card-model__form img {
        width: 100%;
    }
}