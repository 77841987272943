:root {
  --body-bg: #f5f8ff;
  --box-bg: #fff;
  --input-bg: #f5f8ff;
  --txt-color: #2f2d2f;
  --txt-second-color: #ccc;
  --border-color: #4267b2;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.box {
  background-color: var(--box-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.box-header {
  margin-bottom: 30px;
  text-align: center;
}

.drag-area {
  border: 2px dashed rgb(201, 195, 195);
  height: 200px;
  width: 250px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid rgb(179, 170, 170);
}

.drag-area .icon {
  font-size: 50px;
  color: #5256ad;
}

.drag-area header {
  font-size: 16px;
  font-weight: 500;
}

.drag-area button {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #aaaaaa86;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 80%;
  width: 80%;
  object-fit: cover;
  border-radius: 5px;
}

/*  Style upload */

.upload {
  width: 100px;
  position: relative;
}

.upload img {
  border-radius: 50%;
  border: 2px solid var(--green);
  margin-left: 16px;
  object-fit: cover;
}

.upload .round {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--green);
  width: 32px;
  height: 32px;
  line-height: 33px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}

.upload .round input[type="file"] {
  position: absolute;
  transform: scale(2);
  opacity: 0;
}

input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

.danger {
  color: red;
}

.btn-save {
  height: 36px;
  padding: 0 16px;
  border-radius: 36px;
  background: var(--green);
  color: #FBFBFB;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  font-weight: 500;
}

@media (max-width: 1104px) {
  .tablefont {
    font-size: 14px !important;

  }

  .tablefont th {
    font-size: 10px !important;
    padding: 3px !important;

  }
}

@media (max-width: 1002px) {
  .tablefont {
    font-size: 14px !important;

  }

  .tablefont th {
    font-size: 10px !important;
    padding: 3px !important;

  }
}
