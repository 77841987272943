.dropdown-button {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  
  .dropdown-content {
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top: 4px;
    z-index: 1;
  }
  
  .dropdown-content.bottom-left {
    top: 100%;
    left: 0;
  }
  
  .dropdown-content.bottom-right {
    top: 100%;
    right: 0;
    z-index: 10;
  }
  
  .dropdown-content.bottom-centered {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .dropdown-content.top-left {
    bottom: 100%;
    left: 0;
  }
  
  .dropdown-content.top-right {
    bottom: 100%;
    right: 0;
  }
  
  .dropdown-content.top-centered {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .dropdown-content ul {
    list-style-type: none;
    padding: 8px;
    margin: 0;
  }
  
  .dropdown-content li {
    padding: 8px 16px;
    cursor: pointer;
  }
