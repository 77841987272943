/* BOTÓN DEL SIDEBAR */
.botonSidebar {
  margin-top: 23px;
  float: right;
  padding: 0px;
  max-height: 32px;
}

.imgBotonSidebar {
  height: 30px;
  width: 30px;

}

/* BOTON DE GUARDADO */
.buttonParametrize {
  display: block;
  margin-left: auto;
  margin-right: 0px !important;
}

.card-process__circle {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.process-tab {
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  padding: 1.6rem;
  background-color: #fff;
  gap: 1rem;
}

@media (max-width: 576px) {
  .process-tab {
    padding: 15px;
  }
}

.actors-selected__tab {
  display: block;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid #D9D9D9;
  min-height: 25rem;
}

.actors-selected__list {
  max-height: 25rem;
  overflow-y: auto;
}


